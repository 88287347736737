import * as React from "react";
import { seoData } from "../data/seo";
import { navData } from "../data/nav";
import { accordionData, accordionLabel } from "../data/accordion";

import Seo from "../components/seo";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import ContentFloorPlans from "../components/content-floor-plans";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import leftPalms from "../assets/images/compos/marina_compos_1_left.png";
import rightPalms4 from "../assets/images/compos/marina_compos_4_right.png";
import leftMFooterM from "../assets/images/compos/marina-compos_mob_0_left.png";
import rightMFooterM from "../assets/images/compos/marina-compos_mob_0_right.png";

const BeachTowerFloorPlans = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.beachTowerFloorPlans} />

      <section>
        <ContentFloorPlans
          label={accordionLabel.beachTowerAmenities.label}
          btData={accordionData.beachTowerFloorPlans}
          mtData={accordionData.marinaTowerFloorPlans}
          type={"bt"}
        />
      </section>

      <section>
        <ContentNextPage
          nextLink={navData.marinaTower.link}
          nextTitle={navData.marinaTower.next}
          mobHeight={"420px"}
          vertAlign={"center"}
        />
        {width < 600 && <NewComposition imgSrc={leftMFooterM} bottom={"-20px"} left={width < 389 ? "-10px" : "-5px"} height={'88vw'}/>}
        {width < 600 && <NewComposition imgSrc={rightMFooterM} bottom={"30px"} right={"0"} height={width > 400 ? '90vw' : '88vw'}/>}
      </section>

      <Footer />
      {width >= 1024 && <NewComposition imgSrc={leftPalms} bottom={width >= 1300? "20vh" : '30vh'} left={"0"} height={width >= 1300? '100vh' : '90vh'}/>}
      {width >= 1024 && <NewComposition imgSrc={rightPalms4} bottom={width >= 1600? "285px" : "300px"} right={"0"} height={width >= 1300? '75vh' : "70vh"}/>}
    </main>
  );
};

export default BeachTowerFloorPlans;
